import ExternalPayments from '@/util/Purchase';

export default {
	data() {
		return {
			alert: new this.$Alert(),
			retry: new ExternalPayments(),
		};
	},
	computed: {
		orderId() {
			return this.$route.query.order_id;
		},
		hash() {
			return this.$route.query.hash;
		},
		loading() {
			return this.retry.data.loading;
		},
	},
	methods: {
		openExternalPayment(externalPaymentType) {
			// const options = {
			// 	idempotency_id: this.createUuid(),
			// 	// eslint-disable-next-line no-underscore-dangle
			// 	sift_session_id: window._sift_session_id,
			// };

			if (externalPaymentType === 'gcash') {
				this.retry.retryPayment({ hash: this.hash }).then((response) => {
					window.location.href = response.response.redirect_url;
				}).catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
			} else {
				this.retry.retryExternalPayment(this.orderId).then((response) => {
					window.location.href = response.response.redirect_url;
				}).catch(() => {
					this.alert.toast('error', this.translate('default_error_message'));
				});
			}
		},
	},
};
